import '../styles/globals.css'
import 'slick-carousel/slick/slick.css'
import '../styles/slick-theme.css'
import '../styles/slick.css'
import '../styles/style.css'
import type { AppProps } from 'next/app'
import { Fragment } from 'react'
import Head from 'next/head'
import TapFiliate from 'components/core/TapFiliate'
import CjAffiliate from 'components/core/CjAffiliate'
import GoogleTagManagerAttributes from 'components/core/GoogleTagManagerAttributes'

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <Fragment>
      <TapFiliate />
      <CjAffiliate />
      <GoogleTagManagerAttributes />
      <Head>
        <link rel="icon" type="image/x-icon" href="/favicon.png" />
      </Head>
      <Component {...pageProps} />
    </Fragment>
  )
}

export default MyApp
