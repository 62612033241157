import React, { Fragment } from 'react'
import Script from 'next/script'
import Env from 'Env'

const TapFiliate = () => {
  return (
    <Fragment>
      <Script src="https://script.tapfiliate.com/tapfiliate.js" strategy="afterInteractive" />
      <Script id="tapfiliate-tracking" strategy="afterInteractive">
        {`(function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){
  (t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');

  tap('create', '${Env.TapFiliateAccountID}');
  tap('detect', {always_callback: true}, async (err, result) => {
    if (!err) {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const tap_ref = urlParams.get('ref')
        const formData = new FormData()
        formData.append('referral_type', 'tapfiliate')
        formData.append('referral_code', urlParams.get('ref'))
        formData.append('landing_page', window.location.href)
        if (urlParams.get('utm_source')) {
            formData.append('source', urlParams.get('utm_source'))
        }
        formData.append('tracking_id', result.vid)

        const refD = new Date();
        refD.setTime(refD.getTime() + (45*24*60*60*1000));
        let expires = "expires="+ refD.toUTCString();
        let domain = '.' + window.location.hostname.split('.').slice(-2).join(".")
        // let domain = 'localhost';
        document.cookie = 'tap_ref='+ tap_ref + ';' + expires +'; path=/';
        
        // expire cje
        document.cookie = "cje=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" + domain + ";"
        document.cookie = "cj_aid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" + domain + ";"
        document.cookie = "cj_pid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" + domain + ";"
        
        let witopia_url = document.getElementById('witopia_url').getElementsByTagName('a')[0];
        let currentLink = witopia_url.href;
        const elementParams = new URL(currentLink);
        const ref_val = elementParams.searchParams.get('ref')

        if(elementParams.searchParams.get('cjevent')){
          elementParams.searchParams.delete('cjevent')
          elementParams.searchParams.delete('AID')
          elementParams.searchParams.delete('PID')
        }

        if (!ref_val){
          elementParams.searchParams.append('ref', tap_ref)
          witopia_url.href = elementParams.href
        }else{
          const newLink = currentLink.replace(ref_val, tap_ref)
          witopia_url.href = newLink
        }

        await fetch('${Env.SmeApiTrackingEndpoint}', {
            method: 'POST',
            body: formData
        }).then(() => {}).catch((err) => {
            console.log('conversion tracking tapfiliate error', err)
        })
    }
  });`}
      </Script>
    </Fragment>
  )
}

export default TapFiliate
