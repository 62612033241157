import Env from 'Env'
import Head from 'next/head'
import React, { Fragment } from 'react'
import Script from 'next/script'

const GoogleTagManagerAttributes = () => {
  return (
    <Fragment>
      <Script strategy='lazyOnload'>
        {`
          window.dataLayer = window.dataLayer || [];
        `}
      </Script>
      <Script id="google-tagmanager" strategy="lazyOnload">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${Env.GoogleTagManagerID}');
        `}
      </Script>
      <noscript dangerouslySetInnerHTML={{ __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${Env.GoogleTagManagerID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}}></noscript>
    </Fragment>
  )
}

export default GoogleTagManagerAttributes
