const Env = {
  SiteUrl: process.env.NEXT_PUBLIC_SITE_URL as string,
  ApiUrl: process.env.NEXT_PUBLIC_API_URL as string,
  BaseUrl: process.env.NEXT_PUBLIC_BASE_URL as string,
  CdnUrl: (process.env.NEXT_PUBLIC_CDN_URL as string) || '',
  PropsRevalidateTime: parseInt(process.env.NEXT_PUBLIC_PROPS_REVALIDATE_TIME as string) || 60,
  StrapiUploadUrl: process.env.NEXT_PUBLIC_STRAPI_UPLOADS_URL as string,
  GoogleTagManagerID: process.env.NEXT_PUBLIC_GTM_ID as string,
  TapFiliateAccountID: process.env.NEXT_PUBLIC_TAPFILIATE_ACCOUNT_ID as string,
  SmeApiTrackingEndpoint: process.env.NEXT_PUBLIC_SME_API_TRACKING_ENDPOINT as string,
}

export default Env
